.headerText {
  font-size: 3em;
  font-weight: 600;
  color: #000000;
  font-family: monospace;
  padding-top: 20px;
}

.resume h1 {
    font-size: 2.5em;
    color: rgb(0, 167, 167);
    font-family: monospace;
    text-align: left;
    margin-left: 10px;
    font-style: italic;
}

.resume h2 {
    font-size: 1.4em;
    color: #000000;
    text-align: left;
    font-weight: 350;
}

.resume h3 {
    font-size: 1.2em;
    color: #000000;
    font-family: monospace;
    text-align: left;
    margin-left: 10px;
}

p {
    font-size: 1em;
    color: #000000;
    text-align: left;
    margin-left: 10px;
}

ul {
    color: #000000;
}

li {
    font-size: 1em;
    color: #000000;
    text-align: left;
    margin-left: 10px;
}

.expandIcon {
    color: #000000;
    font-size: 24px;
    margin-left: 10px;
}

.accordionTitle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    flex-direction: row;
    align-content: center;
}

.resume {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    max-width: 1200px;
}

@media (max-width: 600px) {
    .resume {
        width: 95%;
    }
}