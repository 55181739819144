.App {
  text-align: center;
  background-color: #000000;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name {
  text-align: center;
  font-size: 3em;
  font-weight: 600;
  color: #eacbb6;
  font-family: monospace;
  transform: translateY(150px);
  transition: transform 0.5s ease;
}

.name.show-picture {
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.links {
  display: flex;
  justify-content: space-between;
  width: 200px;
  transform: translateY(-200px);
  transition: transform 0.5s ease;
}

.links.show-picture {
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.headshot {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-bottom: 50px;
  transform: scale(50%);
  transition: opacity 0.3s ease, transform 0.5s ease;
  opacity: 0;
}

.headshot.show-picture {
  opacity: 1;
  transform: scale(100%);
}

.pictureDiv {
  cursor: pointer;
}

.scrollButton {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

.resume {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}